

nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: clamp(390px, 400px, 700px);
    height: 10vh;
    background-color: var(--black);
    margin-top: 18px;
    z-index: 289;
}

nav a {
    color: var(--white);
    height: 38px;
}

nav svg {
    height: 28px;
    width: 28px;
    position: relative;
    bottom: 7px;
}

nav a:active {
    color: var(--orange);
}