.Card.roast {
  background-color: var(--blue);
  margin-bottom: 10px;
}

.Card.roast .roaster-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  
}

.Card.roast .roaster-header h2 {
  padding: 10px 0 15px 0;
}

.Card.roast .roaster-header h2, 
.Card.roast .roaster-body h3 {
  font-size: 22.5px;
  margin: 0;
}

.Card.roast .roaster-body h3 {
  margin-top: 25px;
}

.Card.roast .roaster-body p {
  font-size: 14.4px;
  margin-top: 12px;
}



.roaster-body p {
  font-size: 14.4px;
  font-weight: normal;
}

.no-bottom-margin {
  margin-bottom: 0;
}

/* Form Styles */

form {
  
}


.input-wrapper {
  position: relative;
  padding: .5rem 0 0;
  display: flex;
  place-content: center;
}

.input-field {
  border: 0;
  width: 78%;
  border-bottom: 1px solid var(--translucent-white);
  outline: 0;
  font-size: 18px;
  color: var(--white);
  padding: 7px 5px;
  padding-right: 2rem;
  background: transparent;
  transition: border-color 0.2s;
  border-radius: 0;
  -webkit-border-radius: 0;
}

.input-field::placeholder {
  color: transparent;
}

.input-field:placeholder-shown ~ .input-label {
  font-size: 14.4px;
  cursor: text;
  color: var(--translucent-white);
  top: 20px;
}

label, .input-field:focus ~ .input-label {
  position: absolute;
  top: 3px;
  left: 20px;
  font-size: 8.9px;
  display: block;
  color: var(--translucent-white);
  transition: 0.3s;
}

.input-field:focus ~ .input-label {
  color: var(--translucent-white);
}

.input-field:focus {
  border-bottom: 1px solid var(--white);
}

.inline-input-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 13px;
}

.inline-input-wrapper .input-label {
  position: relative;
  top: 7px;
  font-size: 14.4px;
  padding-right: 30px;
}

.inline-input-wrapper select {
  width: 30%;
  font-size: 14.4px;
  padding-right: 7px;
}

/* Timer Styles */

.Timer {
  width: 100%;
}

.Timer p {
  font-weight: bold;
  font-size: 55px;
  text-align: center;
  font-variant-numeric: tabular-nums;
  margin: 0;
  margin-top: -18px;
}

/* Status Styles */

.Status {
  margin-left: -17px;
  margin-right: -17px;
  max-width: clamp(390px, 400px, 700px);

}

.Status .row {
  display: flex;
  justify-content: space-between;
  z-index: 10;
  height: 40px;
  padding: 0 17px;
  transition: all .29s ease-out;
  -webkit-transition: all .29s ease-out;
  -moz-transition: all .29s ease-out;
}

.Status .row .step {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  align-items: center;
  z-index: 10;
}

.Status .row .number {
  background-color: var(--light-blue);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.Status .row .number p {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 18px;
  z-index: 11;
}

.Status .row p {
  color: var(--translucent-white);
  transition: all .29s ease-out;
  -webkit-transition: all .29s ease-out;
  -moz-transition: all .29s ease-out;
  margin: 13.2px 0;
  z-index: 11;;
}

.Status .row.active {
  background-color: #3A94C4;
}

.Status .row.active .step p:not(.number p) {
  color: var(--white);
  transform: scale(125%) translateX(10%) translateY(-5%);
  margin: 9px 0;
}

.Status.row.active .time p {
  transform: translateX(-5px);
}

.Status .row.active .number {
  background-color: var(--blue);
}

.active-row {
  position: absolute;
  padding: inherit;
  /* right: 25px; */
  width: 340px;
  height: 40px;
  
  z-index: 0;
}

.roast-button .Button {
  border: 3px solid var(--white);
  padding: 25px 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.FinishRoastForm {
  margin-bottom: 116px;
}

.finish-buttons .Button {
  margin-top: 10px;
}

.finish-buttons .Button:last-child {
  border: 3px solid var(--white);
}
