:root {
  --black: #202020;
  --orange: #E37238;
  --blue: #1D72A0;
  --white: rgb(255, 248, 244);
  --gray: #818282;
  --light-blue: rgba(58, 148, 196, .5);
  --light-orange: rgba(252, 149, 97, .5);
  --translucent-white: rgba(255, 248, 244, .75);
}

/* * {
  outline: 1px dashed red;
} */

.Page {
  height: 88vh;
  padding: 0px 25px;
  padding-top: 18px;
  max-width: clamp(390px, 400px, 700px);
  margin: auto;
  margin-bottom: 10vh;
  overflow-y: scroll;
}

#root {
  /* overflow: hidden; */
  height: 100%;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: auto;
  max-width: clamp(390px, 400px, 700px);
  margin-top: 0;
  height: 100vh;
  overflow: hidden;
}

h1,
h2, 
h3,
h4, 
h5, 
h6,
p {
  color: var(--white);
}

h1 {
  font-size: 22.56px;
}

h2 {
  font-size: 22.5px;
}

h3 {
  font-size: 18px;
}
