.Account {
    overflow-y: scroll;
}

svg {
    color: var(--white);
}

.title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title-bar svg {
    height: 28px;
    width: 28px;
}

.account-info {
    text-align: center;
}

.account-info svg {
    height: 85px;
    width: 85px;
    stroke-width: .35;
    margin-top: 25px;
}

.roasts {
    margin-top: 50px;
}

.logged-out {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 70vh;
}

.logged-out .buttons .Button {
    margin: 14.4px;
}

.Card.CreateAccountForm .Button {
    margin-top: 30px;
}

.Card.CreateAccountForm h2 {
    font-size: 22.5px;
    margin-bottom: 15px;
}

.Card.CreateAccountForm .input-wrapper {
    margin-top: 20px;
}

.Card.LoginForm {
    background-color: var(--blue);
    margin-top: 125px;
    padding-top: 5px;
}

.Card.LoginForm .input-wrapper {
    margin-top: 10px;
}

.Card.LoginForm .Button {
    margin-top: 25px;
}