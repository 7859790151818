.Home {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    top: 0;
}

.Home .center-title {
    margin-top: 57.5px;
}

.Home h1 {
    margin: 0 auto;
    padding: 0;
    margin-top: 10px;
}

.Home h3, .lower-section h3 {
    font-size: 22.56px;
    font-weight: bold;
    margin-bottom: 0;
}

.full-screen-container h1 {
    max-width: clamp(390px, 400px, 700px);
}

.roaster-active .lower-section h3:not(.Card h3) {
    margin-top: 3px;
}

/* .roaster-active .lower-section {
    position: relative;
    bottom: 154px;
    z-index: -1;
} */
