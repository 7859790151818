.Card {
    background-color: var(--orange);
    border-radius: 10px;
    padding: 25px 17px;
    margin: 18px auto;
    max-width: clamp(390px, 400px, 700px);
}

.collapsed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 34.5px;
}

.roast-details h3 {
    margin: 0 0 3px 0;
    font-size: 14.4px;
}

.roast-details p {
    margin: 0;
    font-size: 11.52px;
}

.favorite-container {
    max-height: 20px;
}

.favorite-container svg {
    max-width: 20px;
    max-height: 20px;
    scale: 1;
}

.favorite-container svg:active {
    scale: 1.1;
}

/* Expanded Card Description */
.full-screen-container {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 0px 25px;
    background-color: #202020;
    opacity: 1;
    transition: opacity .5s ease-in;
    z-index: 100;
  }
  
  .expanded {
    margin-top: 42px;
    height: fit-content;
  }
  
  .title-bar {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 10px 0 15px 0;
  }
  
  .title-bar h2 {
    font-size: 22.5px;
    margin: 0;
  }
  
  .title-bar .favorite-container svg {
    height: 24px;
    width: 24px;
  }
  
  hr {
    box-sizing: border-box;
    color: var(--white);
    border: .5px rgba(255, 248, 244, .5) solid;
    width: 100%;
  }
  
  .expanded-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .expanded-description p {
    font-size: 14.4px;
    font-weight: light;
    line-height: 1.5;
    margin: 0;
  }
  
  .roast-details-expanded h3,
  .notes h3 {
    font-size: 18px;
    font-weight: bold;
  }
  
  .roast-details-expanded table {
    width: 100%;
  }
  
  .roast-details-expanded tr {
    box-sizing: border-box;
    width: 100%;
    line-height: 1.75;
  }
  
  .roast-details-expanded .label {
    font-size: 11.52px;
    font-weight: normal;
    color: var(--white);
    width: 32%;
  }
  
  .roast-details-expanded .value {
    font-size: 11.52px;
    font-weight: bold;
    text-align: right;
    color: var(--white);
    width: 18%;
  }
  
  .roast-details-expanded .value.left {
    padding-right: 15px;
  }
  
  .roast-details-expanded .label.right {
    padding-left: 12px;
    width: 36%;
  }
  
  .roast-details-expanded .label.final {
    font-size: 14.4px;
    width: 50%;
  }
  
  .roast-details-expanded .value.final {
    font-size: 18px;
    text-align: left;
    padding-left: 12px;
    width: 50%
  }
  
  .notes-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .notes-head h3 {
    margin: 0;
    padding-left: 4.5px;
  }
  
  .notes-head svg {
    height: 20px;
    width: 20px;
  }
  
  .notes-field {
    background-color: var(--light-orange);
    border-radius: 10px;
    padding: 12px;
    height: 98px;
    margin: 8px 0;
  }
  
  .notes-field p {
    font-size: 11.52px;
    margin: 0;
    padding: 0;
  }

  .notes-field textarea {
    font-size: 11.52px;
    color: var(--white);
    font-family: inherit;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    height: 100%;
    width: 100%;
    resize: none;
  }

  .notes-field textarea:focus-visible {
    outline: none;
    border: none;
  }

  .step-details h4 {
    font-size: 14.4px;
    text-align: right;
    margin: 0;
    margin-bottom: 3px;
    padding: 0;
  }

  .step-details p {
    font-size: 11.52px;
    text-align: right;
    margin: 0;
    padding: 0;
  }