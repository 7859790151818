.Button {
    padding: 13px 0;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.Button:active {
    filter: brightness(125%);
}

.Button p {
    font-size: 22.5px;
    font-weight: bold;
    margin: 0;
    padding: 0;
}